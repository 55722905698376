import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = (function () {
  let imgArray = [];
  for (let i = 1; i <= 15; i++) {
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/18-oct-1/pic${i}.JPG`;
    imgArray.push(imgUrl);
  }

  return imgArray;
})();

export default function MatrPitrAbhinandan2023() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">Matr Pitr Abhinandan</h1>

        <p>
          Chinmaya Vidyalaya , New Delhi, organized a signature event,
          "Matr-Pitra Abhinandan," as a tribute to the very source of our
          existence-our parents. During the event, students paid homage to their
          parents and expressed their love and care for their parents by
          worshipping them. The parents expressed their commitment to nurture
          their children. This emotional moment was further enriched as students
          bowed in deep gratitude and acknowledged the love and care that their
          parents offer unconditionally. The Principal, Ms.Archana Soni,
          emphasized the significance of strengthening familial bonds in today's
          fast-paced world. This event also instilled in students a deeper sense
          of duty towards their parents and a profound appreciation for the
          boundless affection they receive from them. Such solemn events
          transform students into devoted and responsible individuals who
          understand the true essence of our Indian culture.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
